var APP = {};

APP.swiperStats = function(){
    var el = '.s3';

    var swiper = new Swiper(el, {
        freeMode: false,
        loop: true,
        speed: 800,
        slidesPerView: 3,
        autoplay:4000,
        watchSlidesVisibility: true,
        spaceBetweenSlides: 0,
        paginationClickable: true,
        nextButton: $('.swiper-stats-holder').find('.swiper-button-next'),
        prevButton: $('.swiper-stats-holder').find('.swiper-button-prev'),
        breakpoints: {
            767: {
                slidesPerView: 1
            },
            1024: {
                slidesPerView: 2
            }
        },
        onSlideChangeEnd: function(swiper) {
            APP.counter(el, swiper);
        },
        onTouchEnd: function(swiper) {
            APP.counter(el, swiper);
        },
    });
};

APP.counter = function(root, swiper){
    var elRoot = document.querySelector(root),
        elSwiper = elRoot.querySelectorAll('.swiper-slide'),
        elActive = elRoot.querySelectorAll('.swiper-slide-visible');

    var options = {
        useEasing : false,
        useGrouping : true,
        separator : ' ',
        decimal : '.',
        prefix : '',
        suffix : ''
    };

    function formatNumber(nStr) {
        var x, x1, x2, rgx;

        if (nStr === undefined) {
            return false;
        };

        nStr = nStr.toFixed(options.decimals);
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? options.decimal + x[1] : '';
        rgx = /(\d+)(\d{3})/;
        if (options.useGrouping) {
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + options.separator + '$2');
            }
        }
        var value = options.prefix + x1 + x2 + options.suffix;
        if(options.postFormatter != null) {
            value = options.postFormatter(value);
        }
        return value;
    };

    function countOnHiddenCloned(elDataId){

        $.each(swiper.slides, function(i, elRoot) {
            var el;

            if(!$(elRoot).hasClass('swiper-slide-active')) {
                el = $(elRoot).find('*[data-id="'+ elDataId +'"]');
                $.each(el, function(i, el){
                    $(el).html(formatNumber($(el).data('count')));
                    $(el).addClass('counted');
                })
            }

        })
    }

    function initCounter(el) {
        $.each(el, function(i, el) {
            var max = el.getAttribute("data-count");
            var count;

            if ($(el).hasClass('counted')){
                return false;
            };

            if (max !== null) {
                count = new CountUp(el, 0, parseInt(max), 0, 1, options);
                count.start();
            }
            $(el).addClass('counted');
            countOnHiddenCloned(el.getAttribute("data-id"));

        });
    }


    $(elRoot).waypoint(function(direction) {
        $.each(elActive, function(i,el){
            var els = el.querySelectorAll('.js-count');
            initCounter(els);
        });
    }, {
        offset: '80%'
    });
};

APP.init = function(){
    APP.swiperStats()
}

$(document).ready(function(){
    APP.init();
});

 var swiper = new Swiper('.s1', {
    pagination: '.swiper-pagination',
    paginationClickable: true,
});
 var swiper = new Swiper('.s2', {
    pagination: '.swiper-pagination',
    paginationClickable: true
});




 